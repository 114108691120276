<template>
  <div class="container fn text-white">
    <div>
      <div>
        <router-link :to="{ name: 'deposit' }">
          <div class="all-title-head">
            ฝากเงินทรูวอลเลต
          </div>
          <div class="dtx2">
            <span>
              <i
                class="fas fa-angle-left fa-2x"
                style="color: #fff;"
              />
            </span>
          </div>
        </router-link>
      </div>

      <div class="cs mt-2">
        <p class="alert">
          <span style="color: #fff">
            1.) ระบบจะเติมเงินอัตโนมัติเข้ายูสเซอร์ของท่าน &nbsp;<span
              style="color: #ffc800"
            >ภายใน 5 วินาที</span></span>
        </p>

        <p class="alert">
          <span style="color: #fff">
            2.) สามารถโอนเงินเข้าทรูมันนี่วอเลทโดยใช้ &nbsp;<span
              style="color: #ffc800"
            >ชื่อบัญชีและเบอร์โทรศัพท์</span>&nbsp; ที่สมัครโอนเข้ามาเท่านั้น</span>
        </p>
      </div>
      <hr>
      <div class="balance-cl p-1">
        <b-row>
          <b-col cols="8">
            <div>
              <span> หมายเลขโทรศัพท์ </span>
              <p style="font-size: 1.5rem; color: #ff7b00">
                {{ Agent.truemoney_acc }}
              </p>

              <span>ชื่อบัญชี </span>
              <p style="color: #ff7b00">
                {{ Agent.truemoney_name }}
              </p>
            </div>
          </b-col>
          <b-col cols="4">
            <img
              src="/bankIcon/wallet.png"
              alt=""
              height="65"
              class="mt-2 pr-2"
            >
          </b-col>
        </b-row>
      </div>
      <hr>
      <button
        v-clipboard:copy="message"
        class="btnlogout"
        @click="showToast1()"
      >
        <span><i class="fad fa-copy fa-xs" /> คัดลอกเบอร์โทร</span>
      </button>

    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: { BRow, BCol },
  data() {
    return {
      message: '',
      selected: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      User: '',
      Agent: '',
    }
  },
  mounted() {
    this.getUser(this.userData)
    this.getAg()
  },
  methods: {
    showToast1() {
      this.$toast.success('คัดลอกเบอร์โทรสำเร็จ', {
        position: 'top-right',
        timeout: 3475,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: 'fad fa-paste',
        rtl: false,
      })
    },
    getUser(userData) {
      this.$http
        .get(`https://api.ufabangkok.com/api/users/show/${userData.userID}`)
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.User = response.data
        })
        .catch(error => console.log(error))
    },
    getAg() {
      this.$http
        .get('https://api.ufabangkok.com/api/agent/show')
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.Agent = response.data
          this.message = this.Agent.truemoney_acc
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped>
.container {
  max-width: 540px;
}
.notice__list {
  font-size: 18px;
}
.notice__list {
  background-color: rgb(34, 34, 34);
  padding: 15px 24px;
  border: 1px solid #e42126;
  border-radius: 20px;
  margin-bottom: 0.75rem;
}
.notice__list p.alert {
  display: flex;
}
.btn-copy-2 {
  color: #ffc800;
  cursor: pointer;
  position: relative;
  font-size: 1.4rem;
}
.btn {
  border-radius: 38px;
  width: 100vw;
  color: #000 !important;
  background-color: #e42126;
}
.balance-cl {
  background-color: rgba(0, 0, 0, 0.336);
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: rgb(255, 255, 255);
  position: relative;
  box-shadow: #505050 0 2px 10px;
}
/* p.highlight {
  line-height: 1.9;
  font-size: 2.6rem;
} */
.pic {
  max-width: 210px;
}
@media only screen and (max-width: 600px) {
  .pic {
    margin-top: 35px;
    max-width: 160px;
  }
  .pic2 {
    margin-top: 35px;
    max-width: 40px;
  }
}
.neon1 {
  background: rgb(27, 12, 27);
  background: radial-gradient(circle, rgba(27, 12, 27, 0) 0%, #09081f 100%);
  border-radius: 5px;
  padding: 5px 5px;
  /* border: 1px solid blueviolet; */
  /* box-shadow: blueviolet 1px 1px 15px; */
}
.dtx2 {
  position: relative;
  display: flex;
  margin-left: 5px;
  margin-top: -35px;
  justify-content: left;
  color: #000;
  font-weight: 600;
  align-items: left;
}
.cs {
  background: rgba(0, 0, 0, 0.336);
  border-radius: 10px;
  padding: 1rem;
  /* box-shadow: #2a2a2b94 0px 5px 10px 5px; */
}
span {
  font-size: 14px;
  font-weight: 300;
}
/* asdasd */
</style>
